import { render, staticRenderFns } from "./listAgent.vue?vue&type=template&id=2ab397bc&scoped=true&"
import script from "./listAgent.vue?vue&type=script&lang=js&"
export * from "./listAgent.vue?vue&type=script&lang=js&"
import style0 from "./listAgent.vue?vue&type=style&index=0&id=2ab397bc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ab397bc",
  null
  
)

export default component.exports